import * as bootstrap from "bootstrap";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

export const initSwiper = () => {
  if (!document.querySelector(".swiper")) return;

  const swiper = new Swiper(".swiper", {
    modules: [Pagination, Autoplay, Navigation],
    cssMode: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
    },
    autoplay: {
      delay: 2000,
    },

    loop: true,
  });
};
