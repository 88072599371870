/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("@rails/ujs").start();
require("turbolinks").start();
require("jquery"); // for cocoon
require("@nathanvda/cocoon"); // for nested forms

import Hammer from "hammerjs";

import { Notyf } from "notyf";
import "notyf/notyf.min.css";

import "@popperjs/core";
import * as bootstrap from "bootstrap";
import "../stylesheets/application";
import "swiper/swiper-bundle.css"; // swiper

import axios from "axios";
window.axios = axios;

// StimulusJS
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { initSwiper } from "./shared_helpers"; // swiper
const application = Application.start();
const context = require.context("./../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// react_rails gem setup
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Bootstrap - enable tooltips and popovers
document.addEventListener("DOMContentLoaded", function (event) {
  // alert() & toast() will use notyf popup
  const notyf = new Notyf({
    dismissible: true,
    position: {
      x: "center",
      y: "top",
    },
  });
  window.notyf = notyf;
  window.alert = notyf.error.bind(notyf);
  window.toast = notyf.success.bind(notyf);

  // Replace data-confirm dialog with bootstrap modal #confirmationModal (shared/_confirm_dialgo.slim)
  $.rails.confirm = function (message, element) {
    const modal = $("#confirmationModal");
    if (!modal.length) return true;

    modal.find(".modal-body").text(message);
    modal.modal("show");
    modal
      .find("#confirmModalAction")
      .off("click")
      .on("click", function () {
        element.removeAttribute("data-confirm");
        element.click();
      });
    return false;
  };

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
  initSwiper();
});
